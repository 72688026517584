import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ location }) => {

  return (
    <Layout location={location}>
      <Seo title="未知页面" />
      <div className="error">
        <StaticQuery
          query={graphql`
              query {
                  file(relativePath: {eq: "not_found.png"}) {
                      childImageSharp {
                          gatsbyImageData(
                              width: 480
                              placeholder: BLURRED
                              formats: [AUTO, WEBP, AVIF]
                          )
                      }
                  }
              }
          `}
          render={data => <GatsbyImage className="img" image={data.file.childImageSharp.gatsbyImageData} alt="404" />}
        />
        <div className="title">404</div>
        <div className="subtitle">抱歉，您访问的页面不存在</div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

